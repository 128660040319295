import React, { useState, useEffect, Component } from 'react'
import {

  querySonList
} from 'hooks/RaidConfig'

import styled from 'styled-components'
import { loginOut } from 'hooks/RaidConfig'
import { CopyButton } from './CopyButton'
import {

  CardHeader,
  Flex,
  Heading,

  InjectedModalProps,
  CloseIcon,
  ModalTitle,
  IconButton,
  Table,
  Th,
  Td,
} from '@pancakeswap/uikit'
import useToast from 'hooks/useToast'
const InfoBody = styled.div`
  width: 300px;
  min-width: 300px;
  height: 300px;
`

const StyledModalContainer = styled.div`
  position: fixed;
  border-radius: 30px;
  background-color: #000;
  width: 417px;
  height: 600px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;
  min-width: 100px;
  max-width: calc(100% - 10px);
  max-height: 100%;
  z-index: 300;
  float: left;
`
 
const BossSonRow = styled.div`
border: dashed 1px #2c2a2a;
width: 100%;
padding: 3px 50px;
display: flex;
justify-content: space-between;
align-items: center;
height: 60px;
color: #fff;
`

interface ModalProps extends InjectedModalProps {}

const POrder: React.FC<ModalProps> = ({ onDismiss }) => {
  //退出登录
  const { toastSuccess, toastError } = useToast()
  const [inviteLink, setInviteLink] = useState('')
  const [records, setRecords] = useState([])
  const [all, setAll] = useState(0)
  const [all2, setAll2] = useState(0)

  useEffect(() => {
    //
    querySonList(localStorage.getItem('userInfo_phone')).then((data) => {

      console.log("datadatadata",data)
      if (data.code) {

        const alls = data.data.sons;

        const sonList=data.data.sons.filter((res) => res.idx == 1)

        const totalCountAll = alls.reduce((sum, current2) => {
          return sum + current2.count;
      }, 0);

        setAll(totalCountAll)
        setAll2(alls.length)

        sonList.forEach(element => {
          const yejis  = data.data.sons.filter((res) => res.firstuid == element.uid)
          const totalCount = yejis.reduce((sum, current) => {
            return sum + current.count;
        }, 0);
          element.yeji=totalCount

          element.tuandui=yejis.length

        });

        setRecords(sonList)
      }
    })
  }, [])

  //查询账户余额
 

  return (
    <StyledModalContainer>
      <CardHeader style={{ height: '70px' }}>
        <Flex mt={-3}>
          <ModalTitle>
            <Heading>{'推广明细'}</Heading>
          </ModalTitle>
          <IconButton variant="text" onClick={onDismiss}>
            <CloseIcon width="24px" color="text" />
          </IconButton>
        </Flex>
      </CardHeader>

 
      <BossSonRow>团队业绩：{all*3999}&nbsp;&nbsp;&nbsp;  团队人数：{all2}</BossSonRow>
       
      <div style={{ maxHeight: '380px', overflowY: 'scroll' }}>
        <Table>
          <thead>
            <tr>
              <Th textAlign={['center', null, 'left']}> {'ID'}</Th>
              <Th textAlign={['center', null, 'left']}> {'手机号'}</Th>
              <Th textAlign={['center', null, 'left']}> {'个人业绩'}</Th>
              <Th textAlign={['center', null, 'left']}> {'团队业绩'}</Th>
              <Th textAlign={['center', null, 'left']}> {'团队人数'}</Th> 
            </tr>
          </thead>

          <tbody>
            {records.map((record, inde) => (
              <tr>
                <Td>{inde + 1}</Td>
                <Td>{record.user_phone}</Td>
                <Td>￥{record.count*3999}</Td>
                <Td>￥{record.yeji*3999}</Td>
                <Td>
                  {record.tuandui}
                </Td>
               
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </StyledModalContainer>
  )
}
export default POrder
