import { SendSms, base36_to_num, num_to_base36 } from 'hooks/RaidConfig'
import { ToastsProvider, ToastListener } from '../../contexts/ToastsContext'

import { SmsInfo } from 'hooks/modules/SmsInfo'
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  LinkExternal,
  Text,
  Alert,
  Dropdown,
  useMatchBreakpoints,
  useModal,
  Button
} from '@pancakeswap/uikit'

import React, {
  useState,
  useEffect,
  Component,
  useRef,
  useContext,
} from 'react'

import {
  Switch,
  NavBar,
  Checkbox,
  Radio,
  Input,
  Tabs,
  TabBar,
  Badge,
} from 'antd-mobile'
import useToast from 'hooks/useToast'
import BingChongCard from '../common/BingChongCard'
import LoginUserInfo from '../common/LoginUserInfo'
import LoginAccountInfo from '../common/LoginAccountInfo'
import LoginRenzheng from '../common/LoginRenzheng'
import CardMenu from '../common/CardMenu'
import ConectUS from '../common/ConectUS'

import Menu from '../common/Menu'

import styles from './assets/index.module.css'
import TranstsFlow from 'view/common/TranstsFlow'
import WasaiTransferPage from 'view/common/WasaiTransferPage'
import WasaiTochainPage from 'view/common/WasaiTochainPage'
import POrder from 'view/common/POrder'

import Order from 'view/common/Order'

import NftTranferHistory from 'view/common/NftTranferHistory'
import UserCardInfo from 'view/common/UserCardInfo'
import MyContext from 'view/common/CommonProvider'

import BoxGoods from 'view/common/BoxGoods'
import {   WASAI_URL } from 'hooks/RaidConfig'

import Window from './Window'



const CommonHead = ({ setLoginView, loginView }) => {
  const [phone, setPhone] = useState('')

  //const [teamCardView, setTeamCardView] = useState(-1)

  const { teamCardView, setTeamCardView } = useContext(MyContext)

  const [isModalVisible, setIsModalVisible] = useState(false);

 // 替换为实际图片路径

  const [menuView, setMenuView] = useState(false)
  const { isMobile } = useMatchBreakpoints()

  const checkLoginInfo = () => {
    const phones = localStorage.getItem('userInfo_phone')
    if (phones) {
      setPhone(phones)
    }/* else{
      setLoginView(1)
    } */
  }
  /*检查登录状态 */
  useEffect(() => {
    checkLoginInfo()
  }, [loginView])

  /*邀请码 */

  return (
    <>

      <Window
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        >
        <p>这是全屏弹窗的内容</p>
      </Window>
      <div className={`${styles['box_2']} flex-row`}>
        <img style={{ transform: "scale(0.7)" }}
          className={`${styles['image_1']}`}
          src={require('./assets/img/logo2x.png')}
        />


        <div className={`${styles['headinfo']}`}>
          <span className={`${styles['text_211']}`}>
            <a style={{ textDecoration: 'none', color: '#fff' }} href={WASAI_URL+"/#/"}>
              主页
            </a>
          </span>
         {/*  <span className={`${styles['text_211']}`}>
            <a style={{ textDecoration: 'none', color: '#fff' }} href={WASAI_URL+"/#/Info"}>
              简介
            </a>
          </span> */}

     {/*      <span style={{ textDecoration: 'none', color: '#fff' }} className={`${styles['text_211']}`} onClick={() => setIsModalVisible(true)}>
              下载
          </span> */}
        </div>


        <div style={{ position: 'fixed', width:"100px", top: isMobile?"10px":"0px", right: '20px', transform: "scale(1)" }}>
          {phone && (
            <>
              <img
                onClick={() => {
                  setMenuView(!menuView)
                }}
                className={`${styles['label_1']}`}
                src={require('./assets/img/psk82n1rshrhle70g779cxb5am5vx1xlhgl588b3048-4eca-4ed3-b46c-b05f20aaf5cf.png')}
              />
              {menuView && (
                <Menu
                  onDismiss={() => {
                    setLoginView(-2)
                  }}
                  setLoginView={setLoginView}
                  setCardView={(val) => {
                    setTeamCardView(val)
                  }}
                  setMenuView={() => {
                    setMenuView(false)
                  }}
                />
              )}
            </>

            /*  </Dropdown> */
          )}

          {phone && (
            <Text
              style={{
                position: 'absolute',
                left:'65px' ,
                paddingTop:  isMobile?"8px":"20px"
              }}
            >
              {'**'}
              {phone.replace(/.(?=.{4})/g, '')}
            </Text>
          )}
        </div>

        {teamCardView == 0 && (
          <LoginUserInfo
            onDismiss={() => {
              setTeamCardView(-1)
            }}
          />
        )}

        {teamCardView == 1 && (
          <TranstsFlow
            onDismiss={() => {
              setTeamCardView(-1)
            }}
          />
        )}

        {teamCardView == 11 && (
          <WasaiTransferPage
            onDismiss={() => {
              setTeamCardView(-1)
            }}
          />
        )}
 {teamCardView == 13 && (
          <POrder
            onDismiss={() => {
              setTeamCardView(-1)
            }}
          />
        )}
      {teamCardView == 14 && (
          <WasaiTochainPage
            onDismiss={() => {
              setTeamCardView(-1)
            }}
          />
        )}

        {teamCardView == 2 && (
          <BoxGoods
            onDismiss={() => {
              setTeamCardView(-1)
            }}
          />
        )}

        {teamCardView == 3 && (
          <Order
            onDismiss={() => {
              setTeamCardView(-1)
            }}
          />
        )}

        {teamCardView == 12 && (
          <NftTranferHistory
            onDismiss={() => {
              setTeamCardView(-1)
            }}
          />
        )}

        {teamCardView == 4 && (
          <UserCardInfo
            onDismiss={() => {
              setTeamCardView(-1)
            }}
          />
        )}

        {teamCardView == 5 && (
          <LoginAccountInfo
            onDismiss={() => {
              setTeamCardView(-1)
            }}
          />
        )}

        {teamCardView == 6 && (
          <LoginRenzheng
            onDismiss={() => {
              setTeamCardView(-1)
            }}
          />
        )}

        {teamCardView == 7 && (
          <BingChongCard
            onDismiss={() => {
              setTeamCardView(-1)
            }}
          />
        )}
        {teamCardView == 8 && (
          <CardMenu
            onDismiss={() => {
              setTeamCardView(-1)
            }}
          />
        )}
        {teamCardView == 9 && (
          <ConectUS
            onDismiss={() => {
              setTeamCardView(-1)
            }}
          />
        )}

        {!phone && (<div className={`${styles['personInfo']}`}> 
          <Button style={{height:"25px",padding:"0px",borderRadius:"10px",width:"80px",background:"linear-gradient(to right, rgb(182 204 205), rgb(31, 199, 212))"}}
            className={`${styles['text_51']}`}
            onClick={() => {
              console.log('login--view')

              setLoginView(1)
            }}
          >
            登录
          </Button>

         {/*  <Button style={{height:"20px",padding:"0px",borderRadius:"5px"}}
            className={`${styles['text_51']}`}
            onClick={() => {
              console.log('login--view')

              setLoginView(2)
            }}
          >
            注册
          </Button> */}
        </div>


        )}


      </div>
    </>
  )
}

export default CommonHead
