import { SendSms, buyNft, queryTotalNft, wasaiPerson, getRemain } from 'hooks/RaidConfig'
import { ToastsProvider, ToastListener } from '../../contexts/ToastsContext'
import MyContext from '../common/CommonProvider'

import { BuyOrder } from 'hooks/modules/BuyOrder'

import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  LinkExternal,
  Text,
  Alert,
  Dropdown,
  Image,
  Progress,
  Button,
  useMatchBreakpoints,
  InjectedModalProps,
} from '@pancakeswap/uikit'

import React, {
  useState,
  useEffect,
  Component,
  useRef,
  useContext,
} from 'react'

import {
  Switch,
  NavBar,
  Checkbox,
  Radio,
  Input,
  Tabs,
  TabBar,
  Badge,
} from 'antd-mobile'
import useToast from 'hooks/useToast'

import LoginUserInfo from '../common/LoginUserInfo'
import Page from '../common/Page'
import Container from '../common/Container'
import styled, { createGlobalStyle, css } from 'styled-components'

import Login from './LoginNew'
import PayCard from './PayCard'
import PayConfirm from './PayConfirm'
import PayConfirmDeposit from './PayConfirmDeposit'

import CommonHead from './CommonHead2'
interface ModalProps extends InjectedModalProps { }
const IdoStyle = createGlobalStyle<{ loginView: number }>`

html,body{
  overflow-x: hidden;
}

 body {
  background: url('./assets/img/buybg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; 

}

 
 

${({ loginView }) =>
    loginView == 0 &&
    css`
    .adm-input-element {
      color: #fff !important;
      text-align: center;
    }
  `}



`

const StyledPage = styled.div`
  min-height: calc(100vh);
  padding-top: 0px;
  padding-bottom: 16px;
  background: url('./assets/img/buybg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  overflow-y: auto;
  overflow-x: hidden;
`

const BuyBoxBox = styled.div`
  width: 80%;
  background: url('./assets/img/buyup.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  margin: auto;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;

  margin-top: 20px;
  @media screen and (max-width: 968px) {
    width: 90%;
    border-radius: 20px;
  }
`

const BuyBoxLeft = styled.div`
  width: 50%;

  @media screen and (max-width: 968px) {
    width: 100%;

    padding: 30px;
    padding: 60px 30px 50px 30px;
  }
`

const BuyBoxRight = styled.div`
  width: 50%;

  @media screen and (max-width: 968px) {
    width: 100%;
    position: relative;
    overflow: hidden;
  }
`

const Row = styled.div`
  @media screen and (max-width: 968px) {
    width: 100%;
  }
`

const RowFot = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const RowText2 = styled.div`
  margin-top: 10px;
  margin-buttom: 10px;
  margin-left: 50px;
  @media screen and (max-width: 968px) {
    width: 100%;
    margin-top: 10px;
    margin-buttom: 10px;
    margin-left: 0px;
  }
`

const RowFirst = styled.div`
  display: flex;
  margin-top: 70px;
  @media screen and (max-width: 968px) {
    display: block;
    width: 95%;
    margin: auto;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0px;
    padding-right: 2.5%;
    margin-top: 0px;
    padding-left: 2.5%;
  }
`

const RowFirst2 = styled.div`
  @media screen and (max-width: 968px) {
    width: 95%;
    margin: auto;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0px;
    padding-right: 2.5%;
    margin-top: 20px;
    padding-left: 2.5%;
  }
`
const BuyBoxLe = styled.div`
  display: flex;

  @media screen and (max-width: 968px) {
    display: block;
  }
`

const RowPROCESS = styled.div`
  width: 50%;
  margin-left: 25%;
  @media screen and (max-width: 968px) {
    background: url('./assets/img/buyupinit.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    margin: auto;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0px;
    /*  padding-right: 10%; */
    padding: 20px;
    padding-top: 10px;
    padding-buttom: 0px;
    margin-top: 15px;
    border-radius: 5px;
    margin-left: 0%;
  }
`

const RowPROCESS2 = styled.div`
  @media screen and (max-width: 968px) {
    font-size: 10px;
  }
`

const RowPROCESS3 = styled.div`
  @media screen and (max-width: 968px) {
    width: 100%;
    padding-top: 10px;
  }
`

const RowPROCES4 = styled.div`
  width: 100%;
  text-align: center;
  padding: 80px;
  @media screen and (max-width: 968px) {
    padding: 40px;
    text-align: center;
  }
`

const RowText = styled.div`
  margin: auto;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2px;
`
const StyleDiv = styled.div``

const StyleInput = styled(Input)`
  width: 80px;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #ffffff85;
  text-align: center;
  color: #fff !important;
  font-color: #fff;
  margin-top: -4px;
  @media screen and (max-width: 968px) {
    margin-top: -3px;
  }
`

const RowTotal = styled.div`
  background: url('./assets/img/buyupinit.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  min-height: 162px;

  margin-top: 80px;
  width: 555px;
  margin-left: -20px;
  padding: 20px;
  @media screen and (max-width: 968px) {
    width: 100%;
    margin: auto;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
    border-radius: 5px;
  }
`
const RowRightButton = styled(Button)`
  background: url('./assets/img/buybutton.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 30%;
  height: 46px;
  margin-top: 10px;
  margin-left: 40px;
  @media screen and (max-width: 968px) {
    width: 100%;
    margin-left: 0;
  }
`

const RowRightENDButton = styled(Button)`
  background: rgb(165, 164, 164);

  width: 30%;
  height: 46px;
  margin-top: 10px;
  margin-left: 40px;
  @media screen and (max-width: 968px) {
    width: 100%;
    margin-left: 0;
  }
`

const StyledInput = styled(Input)`
  // background-image: url('/images/game/input-frame.png'); // 147 x 68
  background-color: #392515;
  background-repeat: no-repeat;
  background-size: contain;
  color: #fff;
  width: 300px;
  height: 46px;
  border-top: solid 5px #392515;
  border-bottom: solid 8px #392515;
  border-left: solid 10px #392515;
  border-right: solid 10px #392515;
  border-radius: 10px;
  text-align: right;
  padding: 0 3px;
  border-image: url('/images/game/input-frame.png') 10 20 round;
  &:focus:not(:disabled) {
    box-shadow: none;
  }
  &:disabled {
    background-color: #392515;
  }
`

const StyledFoot = styled.div`
  width: 80%;
  overflow-y: auto;
  min-height: 90px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0px;
  padding-top: 50px;
`

interface IndividualNFTPageProps { }

const Main: React.FC = ({ }) => {
  const [loginView, setLoginView] = useState(0)
  const [payCardView, setPayCardView] = useState(0)

  const [readView, setReadView] = useState(true)
  const [buyorder, setBuyorder] = useState<any>()
  const [wasaiInvite, setWasaiInvite] = useState<any>()
  const [nftNumber, setNftNumber] = useState('1')
  const [tips, setTips] = useState('')
  const [progress, setProgress] = useState(1)
  const { isMobile } = useMatchBreakpoints()
  const [limit, setLimit] = useState(0)

  const {
    payDespointConfrimView,
    payConfrimView,
    teamCardView,
    setTeamCardView,
  } = useContext(MyContext)

  let nftRate = 1
  

  
   
/* 
    
    if (Number(nftNumber) >= 7 && Number(nftNumber) < 70) {
         nftRate = 0.4762016
    }else if(Number(nftNumber) >= 70 ){
      nftRate =  0.4
    }
 


   if (wasaiInvite?.userLevel == 1) {

    nftRate = 0.6668

    
    if (Number(nftNumber) >= 7 && Number(nftNumber) < 70) {
         nftRate = 0.4762
    }else if(Number(nftNumber) >= 70 ){
      nftRate =  0.4
    }
  }  



  if (wasaiInvite?.userLevel == 2) {

    nftRate =  0.4762

    if (Number(nftNumber) >= 70 ) {
      nftRate = 0.4 
     }

  }

  if (wasaiInvite?.userLevel == 3) {

    nftRate = 0.4

  }

  if (wasaiInvite?.userLevel == 4) {

    nftRate = 0.3334

  }

  if (wasaiInvite?.userLevel == 5) {

    nftRate = 0.2834

  }   */

  const { toastSuccess, toastError } = useToast()
  const [types, setTypesView] = useState(1)

  const [totalNft, setTotalNft] = useState(0)

  /*购买数量 */

  const onInputChangeNum = (e) => {
    setNftNumber(e.replace(/[^\d]/g, ''))
  }

  const buyBox = async () => {
    const buyOrder = new BuyOrder()
    //测算
    buyOrder.type = 1

    buyOrder.buyNumber = Number(nftNumber)

    const result = await buyNft(buyOrder)

    if (result == '请先登录!') {
      toastError('error', result)
      return
    }

    if (result == '请刷新界面再试！') {
      toastError('error', result)
      return
    }

    if (!result?.success) {
      toastError('error', result.message)
    } else {
      /*       toastSuccess('下单成功，跳转支付中!') */
      if (result?.result?.buyOrder?.payNumber) {
        setBuyorder(result?.result?.buyOrder)
        setPayCardView(1)
        // location.href = result?.result?.buyOrder?.payUrl
      }
    }
  }

  /*  useEffect(() => {
     queryTotalNft().then((result) => {
       if (result?.success) {
         
         setProgress(100000 / 1000)
       }
     })
   }, []) */

  console.log('payConfirm-ppp', payConfrimView)

  const queryData = async () => {
    const result = await wasaiPerson()
    if (result?.result) {
      setWasaiInvite(result?.result?.wasaiInvite)

      /* if (result?.result?.wasaiInvite?.userLevel == 1) {
        setTips('一次性购买 5 个及以上，可享受 6.66 折福利，单价 1999一个！')
      }

      if (result?.result?.wasaiInvite?.userLevel == 2) {
        setTips('一次性购买 70个及以上，可享受 4.76折福利，单价 1429一个！')
      } */
    }
  }
  const queryLimit = async () => {
    const result = await getRemain()
    if (result?.result) {
      setLimit(result?.result?.remain)

      setProgress((100000-result?.result?.remain) / 1000)
      /* if (result?.result?.wasaiInvite?.userLevel == 1) {
        setTips('一次性购买 5 个及以上，可享受 6.66 折福利，单价 1999一个！')
      }

      if (result?.result?.wasaiInvite?.userLevel == 2) {
        setTips('一次性购买 70个及以上，可享受 4.76折福利，单价 1429一个！')
      } */
    }
  }




  useEffect(() => {
    queryData()
    queryLimit()
  }, [])

  return (
    <>
      <StyledPage>
        <IdoStyle loginView={loginView} />
        {loginView >= 1 && (
          <Login setLoginView={setLoginView} typesss={loginView} />
        )}

        {payCardView >= 1 && (
          <PayCard setPayCardView={setPayCardView} buyorder={buyorder} />
        )}

        {payConfrimView && (
          <PayConfirm buyorderId={payConfrimView} payView={true} />
        )}

        {payDespointConfrimView && (
          <PayConfirmDeposit depositId={payDespointConfrimView} />
        )}

        <CommonHead setLoginView={setLoginView} loginView={loginView} />

        <Text
          fontSize="25px"
          style={{
            marginTop: '70px',

            marginLeft: isMobile ? '30px' : '200px',
          }}
        >
          {/*  预售 */}
        </Text>
        <BuyBoxBox
          style={{
            height: isMobile ? 'auto' : '462px',
            marginTop: '0px',
            paddingBottom: "30px",
            paddingLeft: isMobile ? 'auto' : '28%',
          }}
        >
          <BuyBoxLe>
            <BuyBoxLeft>
              <Image
                style={{
                  borderRadius: '20px',
                  backgroundColor: isMobile ? 'rgba(0, 0, 0, 0.5)' : '',
                }}
                width={462}
                height={462}
                src={'./assets/img/home5.gif'}
              />
            </BuyBoxLeft>

            <BuyBoxRight>
              <RowFirst>
                <Row>
                  <Text fontSize="30px" fontWeight={600}>NFT 盲盒</Text>{' '}
                </Row>
                <RowPROCESS>
                  <RowPROCESS2>
                    <Flex  >
                      <Text>已售数量：&nbsp;</Text>
                      <Text>
                        <Flex>
                          <Text>({(100000-limit) / 1000}%)&nbsp;&nbsp;</Text>


                          100,000/
                          <Text color="#7eff94">{100000-limit } </Text>
                        </Flex>
                      </Text>
                    </Flex>
                  </RowPROCESS2>
                  <RowPROCESS3>
                    <Progress variant={'flat'} primaryStep={progress} />
                  </RowPROCESS3>
                </RowPROCESS>
              </RowFirst>
              <RowFirst2>
                <RowTotal>
                  <Row style={{ padding: "0px 0" }} >
                    <Flex style={{ justifyContent: "center" }}>
                      <Text pt={isMobile ? 2 : 4} fontSize={'22px'} fontWeight={600}>
                        单价
                      </Text>
                      <Text pt={2} ml={2} fontSize={isMobile ? '22px' : '50px'} fontWeight={600}>
                      {(Math.ceil(  3999 * nftRate * 100) / 100).toFixed(2)}  RMB
                      </Text>
                    </Flex>
                  </Row>
                  <Row
                    style={{
                      display: isMobile ? 'block' : 'flex',
                      marginTop: isMobile ? '0' : '30px',
                    }}
                  >

                   <RowText2>
                      <Text fontSize="14px" pt={isMobile ? 0 : 3}>
                        {(Number(nftNumber) * 3999 * nftRate).toFixed(2)} RMB
                      </Text>
                    </RowText2> 

                    {isMobile && (
                      <div>
                        <RowText2>
                          <Text
                            fontSize="14px"
                            color="#7292cf"
                            pt={isMobile ? 0 : 3}
                          >
                            {tips && '福利：'}
                          </Text>
                          <Text
                            fontSize="14px"
                            color="#7292cf"
                            pt={isMobile ? 0 : 3}
                          >
                            {tips}
                          </Text>





                        </RowText2>
                        <RowText2> <Flex mt={3}>
                          <Image
                            onClick={() => {
                              if (Number(nftNumber) > 1) {
                                setNftNumber(Number(nftNumber) - 1 + '')
                              }
                            }}
                            mr={10}
                            mt={isMobile ? 1 : 2}
                            width={20}
                            height={20}
                            src={'./assets/img/subtract.png'}
                          />
                          <StyleInput
                            style={{ color: '#ffffff' }}
                            value={nftNumber}
                             disabled
                            onChange={onInputChangeNum}
                          />
                          <Image
                            onClick={() => {
                              if (Number(nftNumber) < 1000000) {
                                setNftNumber(Number(nftNumber) + 1 + '')
                              }
                            }}
                            ml={10}
                            mt={isMobile ? 1 : 2}
                            width={20}
                            height={20}
                            src={'./assets/img/add.png'}
                          />
                        </Flex> </RowText2>
                      </div>
                    )}


                    {limit > 0 && <RowRightButton onClick={buyBox}>立即购买</RowRightButton>}

                    {limit <= 0 && <RowRightENDButton>预售完成</RowRightENDButton>}



                  </Row>
                </RowTotal>
              </RowFirst2>
            </BuyBoxRight>
          </BuyBoxLe>
        </BuyBoxBox>

        <BuyBoxBox style={{ minHeight: '150px', overflow: 'hidden' }}>
          <RowPROCES4>
            <Text fontSize="50px" fontWeight={700}>
              {limit}
            </Text>
            <Text fontSize="30px" fontWeight={300}>
              剩NFT数量
            </Text>
          </RowPROCES4>
        </BuyBoxBox>

        <BuyBoxBox
          style={{ minHeight: '292px', padding: '20px', overflow: 'hidden' }}
        >
          <RowText>
            <Text fontSize="25px" textAlign={'center'}>
              作品信息
            </Text>
          </RowText>
          <RowText style={{ marginTop: '5px' }}>
            <Text fontSize="13px" fontWeight={300} lineHeight={2}>
              作品编号：新哇塞星球
            </Text>
          </RowText>
          <RowText>
            <Text fontSize="13px" fontWeight={300} lineHeight={2}>
              合约地址:{' '}
              <a href="https://evm.confluxscan.net/token/0xd8f5aa267d3a376afa7cb16729382e4d3cc6b2e4?tab=NFT">
                0xd8f5aa267d3a376afa7cb16729382e4d3cc6b2e4
              </a>
            </Text>
          </RowText>
          <RowText>
            <Text fontSize="13px" fontWeight={300} lineHeight={2}>
                 品牌成就：中国NFT第一品牌
            </Text>
          </RowText>

          <RowText>
            <Text fontSize="13px" fontWeight={300} lineHeight={2}>
                 品牌定位：Ai人工智能时代深圳市中心房产
            </Text>
          </RowText>
          <RowText>
            <Text fontSize="13px" fontWeight={300} lineHeight={2}>
               产品数量：限量10万 永不增发
            </Text>
          </RowText>
          <RowText>
            <Text fontSize="13px" fontWeight={300} lineHeight={2}>
            产品风格：国风数字人头像
            </Text>
          </RowText>
        </BuyBoxBox>
        <BuyBoxBox style={{ minHeight: '292px', padding: '20px' }}>
          <RowText>
            <Text fontSize="25px">背景故事</Text>
          </RowText>

          <RowText style={{ marginTop: '0px' }}>
            <Text fontSize="13px" fontWeight={300} lineHeight={2}>

              &nbsp;&nbsp;&nbsp; &nbsp;
              2025年，AI人工智能时代已经开启！   <br />        &nbsp;&nbsp;&nbsp; &nbsp;
              人们对人工智能时代充满着憧憬、希望，同时也伴随着焦虑。此时，AI人工智能就好比90年代的深圳，充满着各种机会，只要参与就已领先。
如果我们在90年代的深圳，有两种方式能够参与并赚到钱：第一种是找一份工作，第二种是购买深圳的房产。

同样2025年的人工智能时代也有两种选择：第一种是选择使用AI工具提升工作效率；第二种是购入AI时代的数字房产NFT。而新哇塞星球的NFT就好像是90年代深圳市中心的房产。  <br />        &nbsp;&nbsp;&nbsp; &nbsp;
回到90年代的深圳，你是选择找一份工作，还是选择购买一套房子呢？时光不能倒回，2025年人工智能时代来了，同样的选择又摆在你的面前：你是选择在人工智能时代用好类似DeepSeek等各类人工智能工具，做AI人工智能时代的优秀“996打工人”，还是购买新哇塞星球NFT，做AI人工智能时代的数字房东，躺赢人工智能时代呢？  <br />    
            </Text>
          </RowText>
        </BuyBoxBox>
        <BuyBoxBox style={{ minHeight: '292px', padding: '20px' }}>
          <RowText>
            <Text fontSize="25px">关于NFT</Text>
          </RowText>

          <RowText style={{ marginTop: '0px' }}>
            <Text fontSize="13px" fontWeight={300} lineHeight={2}>
              &nbsp;&nbsp;&nbsp; &nbsp;
              NFT可以通俗理解为区块链凭证。通常是指开发者在区块链的技术下根据不同公链或者联盟链的协议所发行，特性为不可分割、不可替代、独一无二。NFT常见于文化艺术品领域、
              知识产权的链上发行、流转、确权等作用，能有效保护链上知识产权，防止篡改、造假等，是区块链技术的一类场景应用。
            </Text>
          </RowText>
        </BuyBoxBox>

        <StyledFoot>
          <RowFot>
            {' '}
            <Text
              fontSize="8px"
              fontWeight={300}
              lineHeight={2}
              color={'#909090'}
            >
              <a
                style={{
                  textDecoration: 'none',
                  cursor: 'pointer',
                  color: '#909090',
                }}
                target={'_blank'}
                href="https://beian.miit.gov.cn"
              >
                网站备案：渝ICP备2023000789号
              </a>
            </Text>
          </RowFot>
          <RowFot>
            {' '}
            <Text
              fontSize="8px"
              fontWeight={300}
              lineHeight={2}
              color={'#909090'}
            >
              {' '}
              赠值电信业务许可证：渝B2-20230191
            </Text>
          </RowFot>
          <RowFot>
            {' '}
            <Text
              fontSize="8px"
              fontWeight={300}
              lineHeight={2}
              color={'#909090'}
            >
              {' '}
              ©2023-2033 重庆哇塞宇宙数字科技有限公司 版权所有
            </Text>
          </RowFot>

          <RowFot style={{ paddingTop: '20px' }}>
            <Flex>
              <Text
                fontSize="10px"
                fontWeight={300}
                lineHeight={2}
                onClick={() => {
                  setTeamCardView(9)
                }}
              >
                联系我们
              </Text>
              <Text fontSize="10px" fontWeight={300} lineHeight={2} ml={25}>
                <a
                  style={{ textDecoration: 'none', color: '#fff' }}
                  target={'_blank'}
                  href="https://doc.wasai.space/2.html"
                >
                  隐私政策
                </a>
              </Text>
              <Text fontSize="10px" fontWeight={300} lineHeight={2} ml={25}>
                <a
                  style={{ textDecoration: 'none', color: '#fff' }}
                  target={'_blank'}
                  href="https://doc.wasai.space/1.html"
                >
                  用户协议
                </a>
              </Text>
            </Flex>
          </RowFot>
        </StyledFoot>
      </StyledPage>
    </>
  )
}

class DDS_lanhupage_0 extends Component {
  render() {
    return (
      <>
        <Main />
      </>
    )
  }
}

export default DDS_lanhupage_0
