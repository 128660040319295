


import { useEffect, useCallback, useMemo, } from 'react';


import { ERRORS } from "./modules/errors";
import { GameTeam } from './modules/GameTeam';
import { Battle } from './modules/Battle';

import { SmsInfo } from './modules/SmsInfo';

import { UserInfo } from './modules/UserInfo';

import { StoreGoods } from './modules/StoreGoods';
import { Withdraw } from './modules/Withdraw';


import { Deposits } from './modules/Deposits';
import { BuyOrder } from './modules/BuyOrder';
import { UserCard } from './modules/UserCard';
import { Deposit } from './modules/Deposit';
import { UserCardInfo } from './modules/UserCardInfo';
import { WasaiTransfer } from './modules/WasaiTransfer';




import { UserGoods } from './modules/UserGoods';
import { WasaiTochain } from './modules/WasaiTochain';
// 背景图


// eslint-disable-next-line import/no-mutable-exports
export class LoginUserModules {
  displayName: string;
  email: string;
  account: string;
  emailVerified: boolean;
  photoURL: string;
  uid: string;
  phoneNumber: string;
  providerData: any[];
  accessToken: string;
}

export let LoginUser: LoginUserModules = {
  displayName: "",
  email: "",
  account: "",
  emailVerified: false,
  photoURL: "",
  uid: "",
  phoneNumber: "",
  providerData: [],
  accessToken: "",
}




/* 聚合登录登录信息 */

export const loginOut = async function () {
  // try{
  //   firebase.auth().signOut();  
  // } catch(e) {
  //   console.log('err', e)
  // }

  // User is signed out.
  LoginUser = {
    displayName: "",
    email: "",
    account: "",
    emailVerified: false,
    photoURL: "",
    uid: "",
    phoneNumber: "",
    providerData: [],
    accessToken: "",
  }




  localStorage.removeItem('LoginUser');
  localStorage.setItem('XAccessToken', "");
  localStorage.setItem('userInfo_userid', "");
  localStorage.setItem('userInfo_phone', "");

  localStorage.setItem('myinvite', "");

  localStorage.setItem('myLevel', "");


  return true;

};





export const isLoggedIn = function () {
  const login = localStorage.getItem('LoginUser');
  const accessToken = localStorage.getItem("XAccessToken")

  if (login) {
    const logObj = JSON.parse(login)
    return logObj.uid !== '' && accessToken !== ''
  } else {
    return false
  }
}




   export const HASH_GAME_API = "https://gnapi.wsxq.space/wsxq";  
/* export const HASH_GAME_API = "http://localhost:8080/wsxq/"; */
/* dev */
/* export const HASH_GAME_API = "https://api.wasai.space/jeecg-boot"; */



/* export const WASAI_URL = "https://dev.wasai.space/"; */
/* export const WASAI_URL = "https://wasai.space/"; */
/* export const WASAI_URL = "http://localhost:3000/"; */
   export const WASAI_URL = "https://wsxq.space/";  

/* 配置数据 */
export const API_CONFIG = {
  Login: `${HASH_GAME_API}/sys/login`,
  Logout: `${HASH_GAME_API}/sys/logout`,
  ForgePassword: `${HASH_GAME_API}/auth/forge-password`,
  Register: `${HASH_GAME_API}/auth/register`,
  SendSms: `${HASH_GAME_API}/sys/smsLogin`,

  phoneLogin: `${HASH_GAME_API}/sys/phoneLogin`,
  // get my info
  UserInfo: `${HASH_GAME_API}/sys/user/getUserInfo`,
  // get account info
  getAccount: `${HASH_GAME_API}/game/getAccount`,
  // get bag info
  queryBaglist: `${HASH_GAME_API}/raid/queryBaglist`,
  // get Lottery info
  queryWolfLotteryGoodsList: `${HASH_GAME_API}/raid/queryWolfLotteryGoodsList`,

  // get Lottery info
  queryBagByName: `${HASH_GAME_API}/raid/queryBagByName`,
  // get Lottery info
  queryBagByType: `${HASH_GAME_API}/raid/queryBagByType`,
  // NFT-根据类型+名称查询，非当前用户
  queryBagByNameOutUser: `${HASH_GAME_API}/raid/queryBagByNameOutUser`,


  /*获取历史订单数据 */
  getUserBetOrderHistory: `${HASH_GAME_API}/game/getUserBetOrderHistory`,
  /*用户提现 */
  userWithdrawal: `${HASH_GAME_API}/game/userWithdrawal`,
  userDespoint: `${HASH_GAME_API}/raid/userDespoint`,

  userDespointConfirm: `${HASH_GAME_API}/raid/userDespointConfirm`,

  /*市场表-分页列表查询*/
  marketList: `${HASH_GAME_API}/raid/marketList`,
  marketListForOwner: `${HASH_GAME_API}/raid/marketListForOwner`,

  /*市场-上架*/
  marketAdd: `${HASH_GAME_API}/raid/marketAdd`,
  /*市场-出价*/
  marketBuy: `${HASH_GAME_API}/raid/marketBuy`,

  marketExit: `${HASH_GAME_API}/raid/marketExit`,
  //资金流水
  transactionFlowList: `${HASH_GAME_API}/raid/transactionFlowList`,



  /*队伍列表*/
  teamList: `${HASH_GAME_API}/raidGame/teamList`,
  /*获取出战队伍*/
  getTheBattleTeam: `${HASH_GAME_API}/raidGame/getTheBattleTeam`,
  /*队伍战斗*/
  battle: `${HASH_GAME_API}/raidGame/battle`,

  battleTest: `${HASH_GAME_API}/raidGame/battleTest`,

  /*更新用户名*/
  updateUserRealName: `${HASH_GAME_API}/game/updateUserRealName`,
  /*获取用户名*/
  getUserRealName: `${HASH_GAME_API}/game/getUserRealName`,
  /*获取用户名*/
  getRemain: `${HASH_GAME_API}/game/getRemain`,



  /*商城购买*/
  buyFromStore: `${HASH_GAME_API}/raidGame/buyFromStore`,

  /*获取点亮 */
  getLight: `${HASH_GAME_API}/raidGame/getLight`,

  /*获取数据 */
  getStoreGoods: `${HASH_GAME_API}/raidGame/getStoreGoods`,


  /*查询提现记录--个人 */
  queryPersonWithdrawList: `${HASH_GAME_API}/raidGame/queryPersonWithdrawList`,
  /*查询提现记录 */
  queryWithdrawList: `${HASH_GAME_API}/raidGame/queryWithdrawList`,

  /*查询用户资金流水等详细详情 */
  queryUserAccountDetail: `${HASH_GAME_API}/raidGame/queryUserAccountDetail`,
  /*提现审核 */
  auditWithdraw: `${HASH_GAME_API}/raidGame/auditWithdraw`,
  /*提现审核 */
  editEquipment: `${HASH_GAME_API}/raid/editEquipment`,

  /*根据地址查询uid */
  queryUserInfoByAddress: `${HASH_GAME_API}/raidGame/queryUserInfoByAddress`,
  /*冻结/解冻用户 */
  froze: `${HASH_GAME_API}/raidGame/froze`,

  /*token充值数据查询 */
  tokenDepositsData: `${HASH_GAME_API}/raidGame/tokenDepositsData`,
  /*购买盲盒 */
  buyNft: `${HASH_GAME_API}/wasai/buyNft`,

  /*支付确认 */
  payConfirm: `${HASH_GAME_API}/wasai/payConfirm`,

  /*buyOrderList-分页列表查询*/
  buyOrderList: `${HASH_GAME_API}/wasai/buyOrderList`,

  /*buyOrderList-分页列表查询*/
  wasaiPerson: `${HASH_GAME_API}/wasai/wasaiPerson`,

  /*buyOrderList-分页列表查询*/
  openKu: `${HASH_GAME_API}/wasai/openKu`,
  /*buyOrderList-分页列表查询*/
  queryTotalNft: `${HASH_GAME_API}/wasai/queryTotalNft`,

  /*购买盲盒 */
  bandCard: `${HASH_GAME_API}/wasai/bandCard`,
  /*buyOrderList-分页列表查询*/
  queryCardInfo: `${HASH_GAME_API}/wasai/queryCardInfo`,

  /*空投盲盒 */

  airdrop: `${HASH_GAME_API}/raid/airdrop2`,
  /*身份信息认证 */

  idCardBind: `${HASH_GAME_API}/wasai/idCardBind`,

  /*绑卡申请 */

  bindBankCardApply: `${HASH_GAME_API}/wasai/bindBankCardApply`,

  /*绑卡确认 */

  bindBankCardInfo: `${HASH_GAME_API}/wasai/bindBankCardInfo`,

  /*快捷支付确认 */
  fastPayConfirm: `${HASH_GAME_API}/wasai/fastPayConfirm`,

  /*盲盒转赠 */
  nftTransfer: `${HASH_GAME_API}/wasai/nftTransfer`,

  /*盲盒上链*/
  toChainUser: `${HASH_GAME_API}/wasai/toChainUser`,
  /*快捷支付确认 */
  userDespointFastPayConfirm: `${HASH_GAME_API}/raid/userDespointFastPayConfirm`,


  /*nftTransferHistory-分页列表查询*/
  nftTransferHistory: `${HASH_GAME_API}/wasai/nftTransferHistory`,
}





export const base36_to_num = (value) => {
  var str_to_num = {
    f: '0',
    m: '1',
    r: '2',
    c: '3',
    x: '4',
    e: '5',
    t: '6',
    j: '7',
    p: '8',
    a: '9',
    z: '10',
    h: '11',
    v: '12',
    l: '13',
    s: '14',
    d: '15',
    n: '16',
    w: '17',
    g: '18',
    y: '19',
    k: '20',
    b: '21',
    u: '22',
    o: '23',
    q: '0',
    i: '0',
  }

  var l = value.length

  var v = value.toString().toLowerCase()
  var ret = 0
  for (var i = l - 1; i >= 0; i--) {
    ret = Number(ret) * 24 + Number(str_to_num[v.slice(i, i + 1)])
  }
  return ret
}
export const num_to_base36 = (value) => {
  var num_to_str = {
    0: 'F',
    1: 'M',
    2: 'R',
    3: 'C',
    4: 'X',
    5: 'E',
    6: 'T',
    7: 'J',
    8: 'P',
    9: 'A',
    10: 'Z',
    11: 'H',
    12: 'V',
    13: 'L',
    14: 'S',
    15: 'D',
    16: 'N',
    17: 'W',
    18: 'G',
    19: 'Y',
    20: 'K',
    21: 'B',
    22: 'U',
    23: 'O',
    /*    0: 'Q',
       0: 'I', */
  }
  if (value == 0) return '$$'
  var ret = ''
  var v = value
  while (v > 0) {
    ret = ret + num_to_str[v % 24]
    v = Math.floor(v / 24)
  }
  v = 0
  while (ret.length < 4) {
    if (v === 0) ret = ret + 'F'
    else if (v === 1) ret = ret + 'Q'
    else if (v === 2) ret = ret + 'I'
    else if (v === 3) ret = ret + 'F'
    v++
  }

  return ret
}


/** 登录方法 */
export const LoginT = async () => {
  let XAccessToken = localStorage.getItem('XAccessToken');
  const login = localStorage.getItem('LoginUser');

  if (!login) return
  // eslint-disable-next-line no-eval
  const loginUser = eval(JSON.parse(login));
  if (loginUser == null || loginUser === "") {
    return;
  }

  /*   console.log("XAccessToken", XAccessToken, "LoginUser.uid", loginUser.uid, typeof XAccessToken); */
  if ((XAccessToken == null || XAccessToken === "" || XAccessToken === 'undefined') && loginUser.uid !== "") {

    console.log("loginstart");

    // XAccessToken = "1";

    const response = await fetch(API_CONFIG.Login, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken || '',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: loginUser.email,
        password: loginUser.uid
      }),
    })
    if (response.status === 404) {
      loginOut();
    }

    if (response.status === 200) {
      const result = await response.json();


      if (!result?.success) {
        loginOut();
        if (result?.message === "该用户已冻结") {
          return false;
        } else {
          return
        }

      } else {

        // 设置token
        localStorage.setItem('XAccessToken', result.result.token);

        localStorage.setItem('userInfo_userid', result.result.userInfo.id);

        console.log("responseresponse", result);
        console.log("responseresponse3", result.result.userInfo);
        return true;
      }
    }
  }
}

/* 获取个人信息 */
// eslint-disable-next-line consistent-return
export const getUsertInfo = async () => {
  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessToken", XAccessToken, "LoginUser.uid", LoginUser.uid); */
  if ((XAccessToken) && LoginUser.uid !== "") {
    const response = await fetch(`${API_CONFIG.UserInfo}?token=${XAccessToken}`, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
      }
    })

    console.log("response-getUsertInfo", response);
    if (response.status === 401) {
      /* loginOut(); */
    }

    if (response.status === 200) {
      const result = await response.json();
      const { userInfo } = result;

      // eslint-disable-next-line no-empty
      if (!result?.success) {

      } else {
        // 设置token

        return userInfo;
      }


    }
  }

}



/* 获取余额信息 */
export const getAccountInfo = async () => {

  const acountInfo = {
    BUSD: '0',
    RAID: '0',
    integral: '0'
  }

  const XAccessToken = localStorage.getItem('XAccessToken');
  const uid = localStorage.getItem('userInfo_userid');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */

  if ((XAccessToken) && uid !== "") {
    const response = await fetch(API_CONFIG.getAccount, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result?.success) {
        // 设置token
        const accountList = result.result.accountlist;
        console.log("response-accountList", accountList, "accountList.length", accountList.length);

        if (accountList.length > 0) {
          for (let i = 0; i < accountList.length; i++) {
            const account = accountList[i];
            if (account.coin != null && account.coin === "BUSD") {
              acountInfo.BUSD = account.normalBalance.toString();
            }
            if (account.coin != null && account.coin === "RAID") {
              acountInfo.RAID = account.normalBalance.toString();
            }
            if (account.coin != null && account.coin === "integral") {
              acountInfo.integral = account.normalBalance.toString();
            }
          }
        }
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

  return acountInfo;
}




/* 获取用户背包数据信息 */
export const queryBaglist = async () => {

  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */
  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.queryBaglist, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      },
    })
    console.log("response=", response)

    if (response.status === 200) {
      const result = await response.json();
      if (result?.success) {
        // 设置token
        const userGoodsList = result.result.userGoodsList;

        console.log("response-userGoodsList", userGoodsList);
        return userGoodsList;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}


/* 用户背包物品-根据名称查询 */
export const queryBagByName = async (goodsName: string) => {

  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */
  console.log("response-wolfUserGoods-XAccessToken", XAccessToken);
  if ((XAccessToken)) {
    const response = await fetch(`${API_CONFIG.queryBagByName}?token=${XAccessToken}&goodsName=${goodsName}`, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }
    })

    console.log("result-response", response);
    if (response.status === 200) {
      const result = await response.json();

      if (result?.success) {
        // 设置token 
        const userGoods = result.result.userGoods;
        console.log("response-userGoods", userGoods);
        return userGoods;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}


/* 用户背包物品-根据类型查询 */
export const queryBagByType = async (goodsType: string, goodsName: string) => {

  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */
  console.log("response-userGoods-XAccessToken", XAccessToken);
  if ((XAccessToken)) {
    const response = await fetch(`${API_CONFIG.queryBagByType}?token=${XAccessToken}&goodsType=${goodsType}&goodsName=${goodsName}`, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }
    })

    console.log("result-response", response);
    if (response.status === 200) {
      const result = await response.json();

      if (result?.success) {
        // 设置token 
        const userGoods = result.result.userGoods;
        console.log("response-userGoods", userGoods);
        return userGoods;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}


export const queryBagByNameOutUser = async (goodsName: string) => {

  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */
  console.log("response-userGoods-XAccessToken", XAccessToken);
  if ((XAccessToken)) {

    console.log("goodsNamegoodsNamegoodsName", goodsName)
    const response = await fetch(`${API_CONFIG.queryBagByNameOutUser}?token=${XAccessToken}&goodsName=${goodsName.replace("#", "%23")}`, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }
    })

    console.log("result-response", response);
    if (response.status === 200) {
      const result = await response.json();

      if (result?.success) {
        // 设置token 
        const userGoods = result.result.userGoods;
        console.log("response-userGoods", userGoods);
        return userGoods;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}





/* 获取市场列表 */
export const marketList = async (params: any, pageNo: string, pageSize: string) => {
  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */
  if ((XAccessToken)) {

    let url = API_CONFIG.marketList;
    if (params) {
      let paramsArray: any[] = [];
      //拼接参数

      Object.keys(params).forEach(key => paramsArray.push(key + '=' + (typeof params[key] == 'undefined' ? "" : params[key])))
      if (url.search(/\?/) === -1) {
        url += '?' + paramsArray.join('&')
      } else {
        url += '&' + paramsArray.join('&')
      }
    }

    url += '&pageNo=' + pageNo
    url += '&pageSize=' + pageSize
    const response = await fetch(url, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result?.success) {

        console.log("response-marketList", result);
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}



/* 获取市场列表 */
export const marketListForOwner = async (params: any, pageNo: string, pageSize: string) => {
  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */
  if ((XAccessToken)) {

    let url = API_CONFIG.marketListForOwner;
    if (params) {
      let paramsArray: any[] = [];
      //拼接参数

      Object.keys(params).forEach(key => paramsArray.push(key + '=' + (typeof params[key] == 'undefined' ? "" : params[key])))
      if (url.search(/\?/) === -1) {
        url += '?' + paramsArray.join('&')
      } else {
        url += '&' + paramsArray.join('&')
      }
    }

    url += '&pageNo=' + pageNo
    url += '&pageSize=' + pageSize
    const response = await fetch(url, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result?.success) {

        console.log("response-marketList", result);
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}



/* 获取队伍列表 */
export const teamList = async () => {
  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */
  if ((XAccessToken)) {
    let url = API_CONFIG.teamList;
    const response = await fetch(url, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result?.success) {
        console.log("response-teamList", result.result.gameTeamList);
        return result.result.gameTeamList;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}



/* 获取出战队伍*/
export const getTheBattleTeam = async () => {
  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */
  if ((XAccessToken)) {
    let url = API_CONFIG.getTheBattleTeam;
    const response = await fetch(url, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result?.success) {
        console.log("response-gameTeam", result.result.gameTeam);
        return result.result.gameTeam;
      }
    } else if (response.status === 401) {
      await loginOut()
      //throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}





/* 准备战斗 */
export const battleFight = async (battle: Battle) => {

  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */

  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.battle, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify(
        battle
      ),
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result) {
        // 放置结果数据 
        console.log("response-battleFight", result);
        return result;
      } else {
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}



/* 准备测算 */
export const battleFightTest = async (battle: Battle) => {

  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */

  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.battleTest, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify(
        battle
      ),
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result) {
        // 放置结果数据 
        console.log("response-battleFight", result);
        return result;
      } else {
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}


/*获取用户名 */
export const getUserRealName = async () => {
  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */
  if ((XAccessToken)) {
    let url = API_CONFIG.getUserRealName;
    const response = await fetch(url, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result?.success) {
        console.log("response-getUserRealName", result);
        localStorage.setItem('userInfo_userid', result);
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }
}

/*更新用户名 */
export const updateUserRealName = async (userInfo: UserInfo) => {
  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */

  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.updateUserRealName, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify(
        userInfo
      ),
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result) {
        // 放置结果数据 
        console.log("response-battleFight", result);
        return result;
      } else {
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    } else if (response.status === 404) {
      const result = await response.json();
      return result;
    }
  }

}


/* 商城购买数据 */
export const buyFromStore = async (storeGoods: StoreGoods) => {

  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */

  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.buyFromStore, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify(
        storeGoods
      ),
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result) {
        // 放置结果数据 
        console.log("response-battleFight", result);
        return result;
      } else {
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}


/*获取点亮数据 */
export const getLight = async (params: any) => {
  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */
  if ((XAccessToken)) {

    let url = API_CONFIG.getLight;
    if (params) {
      let paramsArray: any[] = [];
      //拼接参数

      Object.keys(params).forEach(key => paramsArray.push(key + '=' + (typeof params[key] == 'undefined' ? "" : params[key])))
      if (url.search(/\?/) === -1) {
        url += '?' + paramsArray.join('&')
      } else {
        url += '&' + paramsArray.join('&')
      }
    }

    const response = await fetch(url, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result?.success) {
        console.log("response-getMaxLevel", result.result.maxLevel);
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }
}





/* 获取市场列表 */
export const getStoreGoods = async (params: any, pageNo: string, pageSize: string) => {
  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */
  if ((XAccessToken)) {

    let url = API_CONFIG.getStoreGoods;
    if (params) {
      let paramsArray: any[] = [];
      //拼接参数

      Object.keys(params).forEach(key => paramsArray.push(key + '=' + (typeof params[key] == 'undefined' ? "" : params[key])))
      if (url.search(/\?/) === -1) {
        url += '?' + paramsArray.join('&')
      } else {
        url += '&' + paramsArray.join('&')
      }
    }

    url += '&pageNo=' + pageNo
    url += '&pageSize=' + pageSize
    const response = await fetch(url, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result?.success) {

        console.log("response-marketList", result);
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}





/* 账户流水数据 */
export const transactionFlowList = async (params: any, pageNo: string, pageSize: string) => {
  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */
  if ((XAccessToken)) {

    let url = API_CONFIG.transactionFlowList;
    if (params) {
      let paramsArray: any[] = [];
      //拼接参数

      Object.keys(params).forEach(key => paramsArray.push(key + '=' + (typeof params[key] == 'undefined' ? "" : params[key])))
      if (url.search(/\?/) === -1) {
        url += '?' + paramsArray.join('&')
      } else {
        url += '&' + paramsArray.join('&')
      }
    }

    url += '&pageNo=' + pageNo
    url += '&pageSize=' + pageSize
    const response = await fetch(url, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result?.success) {

        console.log("response-transactionFlowList", result);
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}






/* 查询提现记录--个人 */
export const queryPersonWithdrawList = async (params: any, pageNo: string, pageSize: string) => {
  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */
  if ((XAccessToken)) {

    let url = API_CONFIG.queryPersonWithdrawList;
    if (params) {
      let paramsArray: any[] = [];
      //拼接参数

      Object.keys(params).forEach(key => paramsArray.push(key + '=' + (typeof params[key] == 'undefined' ? "" : params[key])))
      if (url.search(/\?/) === -1) {
        url += '?' + paramsArray.join('&')
      } else {
        url += '&' + paramsArray.join('&')
      }
    }

    url += '&pageNo=' + pageNo
    url += '&pageSize=' + pageSize
    const response = await fetch(url, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result?.success) {

        console.log("response-queryPersonBattleList", result);
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}




/* 查询提现记录 */
export const queryWithdrawList = async (params: any, pageNo: string, pageSize: string) => {
  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */
  if ((XAccessToken)) {

    let url = API_CONFIG.queryWithdrawList;
    if (params) {
      let paramsArray: any[] = [];
      //拼接参数

      Object.keys(params).forEach(key => paramsArray.push(key + '=' + (typeof params[key] == 'undefined' ? "" : params[key])))
      if (url.search(/\?/) === -1) {
        url += '?' + paramsArray.join('&')
      } else {
        url += '&' + paramsArray.join('&')
      }
    }

    url += '&pageNo=' + pageNo
    url += '&pageSize=' + pageSize
    const response = await fetch(url, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result?.success) {

        console.log("response-queryPersonBattleList", result);
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}


/*  提现  */
export const auditWithdraw = async (withdraw: Withdraw) => {

  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */


  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.auditWithdraw, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify(
        withdraw
      ),
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result) {
        // 放置结果数据 
        console.log("response-raidBattleCount", result);
        return result;
      } else {
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}


/* 查询用户资金流水详情 */
export const queryUserAccountDetail = async (withdraw: Withdraw) => {
  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */
  if ((XAccessToken)) {


    let url = API_CONFIG.queryUserAccountDetail;
    if (withdraw) {
      let paramsArray: any[] = [];
      //拼接参数

      Object.keys(withdraw).forEach(key => paramsArray.push(key + '=' + (typeof withdraw[key] == 'undefined' ? "" : withdraw[key])))
      if (url.search(/\?/) === -1) {
        url += '?' + paramsArray.join('&')
      } else {
        url += '&' + paramsArray.join('&')
      }
    }



    const response = await fetch(url, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result?.success) {

        console.log("response-queryUserAccountDetail", result);
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}



/*  编辑装备  */
export const editEquipment = async (userGoods: UserGoods, gameTeam: GameTeam) => {

  const XAccessToken = localStorage.getItem('XAccessToken');



  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.editEquipment, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify(
        { goodsName: userGoods.goodsName, equipment: userGoods.equipment, gameTeamId: gameTeam.id }
      ),
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result) {
        // 放置结果数据 
        console.log("response-editEquipment", result);
        return result;
      } else {
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}





/*  编辑装备  */
export const buyNft = async (buyOrder: BuyOrder) => {

  const XAccessToken = localStorage.getItem('XAccessToken');


  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.buyNft, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify(
        buyOrder
      ),
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result) {
        // 放置结果数据 
        console.log("response-buyOrder", result);
        return result;
      } else {
        return result;
      }
    } else if (response.status === 401) {
      //await loginOut()
      return "请先登录!"
    }
  } else {
    return "请先登录!";
  }

}




/*  payConfirm  */
export const payConfirm = async (buyOrder: BuyOrder) => {

  const XAccessToken = localStorage.getItem('XAccessToken');


  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.payConfirm, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify(
        buyOrder
      ),
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result) {
        // 放置结果数据 
        console.log("response-payConfirm", result);
        return result;
      } else {
        return result;
      }
    } else if (response.status === 401) {
      //await loginOut()
      return "请先登录!"
    }
  } else {
    return "请先登录!";
  }

}




/* 充值    */
export const userDespoint = async (deposit: Deposit) => {

  const XAccessToken = localStorage.getItem('XAccessToken');


  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.userDespoint, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify(
        deposit
      ),
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result) {
        // 放置结果数据 
        console.log("response-userDespoint", result);
        return result;
      } else {
        return result;
      }
    } else if (response.status === 401) {
      //await loginOut()
      return "请先登录!"
    }
  } else {
    return "请先登录!";
  }

}



/* 充值 payConfirm  */
export const payDepositConfirm = async (deposit: Deposit) => {

  const XAccessToken = localStorage.getItem('XAccessToken');


  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.userDespointConfirm, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify(
        deposit
      ),
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result) {
        // 放置结果数据 
        console.log("response-payConfirm", result);
        return result;
      } else {
        return result;
      }
    } else if (response.status === 401) {
      //await loginOut()
      return "请先登录!"
    }
  } else {
    return "请先登录!";
  }

}



/*  冻结/解冻用户  */
export const froze = async (user: UserInfo) => {
  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */
  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.froze, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify(
        user
      ),
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result) {
        // 放置结果数据 
        console.log("response-raidBattleCount", result);
        return result;
      } else {
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}




/* 根据地址查询uid */
export const queryUserInfoByAddress = async (user: UserInfo) => {
  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */
  if ((XAccessToken)) {
    let url = API_CONFIG.queryUserInfoByAddress;

    if (user) {
      let paramsArray: any[] = [];
      //拼接参数

      Object.keys(user).forEach(key => paramsArray.push(key + '=' + (typeof user[key] == 'undefined' ? "" : user[key])))
      if (url.search(/\?/) === -1) {
        url += '?' + paramsArray.join('&')
      } else {
        url += '&' + paramsArray.join('&')
      }
    }

    const response = await fetch(url, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result?.success) {

        console.log("response-queryUserInfoByAddress", result);
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}





/*  token充值数据查询  */
export const tokenDepositsData = async (deposits: Deposits, pageNo: string, pageSize: string) => {
  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */
  if ((XAccessToken)) {
    let url = API_CONFIG.tokenDepositsData;

    if (deposits) {
      let paramsArray: any[] = [];
      //拼接参数

      Object.keys(deposits).forEach(key => paramsArray.push(key + '=' + (typeof deposits[key] == 'undefined' ? "" : deposits[key])))
      if (url.search(/\?/) === -1) {
        url += '?' + paramsArray.join('&')
      } else {
        url += '&' + paramsArray.join('&')
      }
    }
    url += '&pageNo=' + pageNo
    url += '&pageSize=' + pageSize
    const response = await fetch(url, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result?.success) {

        console.log("response-queryUserInfoByAddress", result);
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}





/*  发送短信  */
export const SendSms = async (smsInfo: SmsInfo) => {

  const response = await fetch(API_CONFIG.SendSms, {
    method: 'post', headers: {
      'X-Access-Token': '123',
      'Content-Type': 'application/json',
    }, body: JSON.stringify(
      smsInfo
    ),
  })
  console.log("response-raidBattleCount", response);
  if (response.status === 200) {
    const result = await response.json();
    if (result) {
      // 放置结果数据 
      console.log("response-raidBattleCount", result);
      return result;
    } else {
      return result;
    }
  } else if (response.status === 401) {
    await loginOut()
    throw new Error(ERRORS.SESSION_EXPIRED)
  }


}




/*  发送短信  */
export const openKu = async () => {

  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */
  if ((XAccessToken)) {

    const response = await fetch(API_CONFIG.openKu, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'Content-Type': 'application/json',
      }
    })
    console.log("response-raidBattleCount", response);
    if (response.status === 200) {
      const result = await response.json();
      if (result) {
        // 放置结果数据 
        console.log("response-raidBattleCount", result);
        return result;
      } else {
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }


}





/** 登录方法 */
export const phoneLogin = async (smsInfo: SmsInfo) => {
  let XAccessToken = localStorage.getItem('XAccessToken');


  console.log("XAccessToken", XAccessToken)

  /* if ((XAccessToken == null || XAccessToken === "" || XAccessToken === 'undefined')) { */

  console.log("loginstart");

  // XAccessToken = "1";

  const response = await fetch(API_CONFIG.phoneLogin, {
    method: 'post', headers: {
      'X-Access-Token': '123',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(
      smsInfo
    ),
  })
  console.log("login result.result.response", response);
  if (response.status === 200) {
    const result = await response.json();

    if (!result?.success) {
      loginOut();
      if (result?.message === "该用户已冻结") {
        return "该用户已冻结";
      } else {
        return result?.message;
      }

    } else {

      console.log("login result.result.token", result.result.token);

      // 设置token
      localStorage.setItem('XAccessToken', result.result.token);

      localStorage.setItem('userInfo_userid', result.result.userInfo.id);

      localStorage.setItem('userInfo_phone', result.result.userInfo.phone);

      localStorage.setItem('myinvite', result.result.myinvite);

      localStorage.setItem('myLevel', result.result.myLevel);

      return "true";
    }
  } else {
    return false
  }
  /* } else {
    return true;
  } */
}




/* 查询提现记录--个人 */
export const buyOrderList = async (params: any, pageNo: string, pageSize: string) => {
  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */
  if ((XAccessToken)) {

    let url = API_CONFIG.buyOrderList;
    if (params) {
      let paramsArray: any[] = [];
      //拼接参数

      Object.keys(params).forEach(key => paramsArray.push(key + '=' + (typeof params[key] == 'undefined' ? "" : params[key])))
      if (url.search(/\?/) === -1) {
        url += '?' + paramsArray.join('&')
      } else {
        url += '&' + paramsArray.join('&')
      }
    }

    url += '&pageNo=' + pageNo
    url += '&pageSize=' + pageSize
    const response = await fetch(url, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result?.success) {

        console.log("response-queryPersonBattleList", result);
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}




/* 查询个人信息 */
export const wasaiPerson = async () => {
  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */
  if ((XAccessToken)) {

    let url = API_CONFIG.wasaiPerson;

    const response = await fetch(url, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result?.success) {

        console.log("response-wasaiPerson", result);
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}


/* 查询个人信息 */
export const getRemain = async () => {
  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */
  

    let url = API_CONFIG.getRemain;

    const response = await fetch(url, {
      method: 'get', headers: {
        'X-Access-Token': '123',
        'token': '123',
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result?.success) {

        console.log("response-getRemain", result);
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
 

}


/* 查询销售总量 */
export const queryTotalNft = async () => {


  let url = API_CONFIG.queryTotalNft;

  const response = await fetch(url, {
    method: 'get', headers: {
      'X-Access-Token': '123',
      'token': '123',
      'Content-Type': 'application/json',
    },
  })
  if (response.status === 200) {
    const result = await response.json();
    if (result?.success) {

      console.log("response-wasaiPerson", result);
      return result;
    }
  } else if (response.status === 401) {

    throw new Error(ERRORS.SESSION_EXPIRED)
  }
}


/* 查询绑卡信息 */
export const queryCardInfo = async () => {

  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */
  if ((XAccessToken)) {

    let url = API_CONFIG.queryCardInfo;

    const response = await fetch(url, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result?.success) {

        console.log("response-wasaiPerson", result);
        return result;
      }
    } else if (response.status === 401) {

      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }
}




/*  编辑装备  */
export const bandCard = async (userCard: UserCard) => {

  const XAccessToken = localStorage.getItem('XAccessToken');


  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.bandCard, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify(
        userCard
      ),
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result) {
        // 放置结果数据 
        console.log("response-bandCard", result);
        return result;
      } else {
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      return "请先登录"
    }
  } else {
    return "请刷新界面再试！";
  }

}



/*  空投盲核  */
export const airdrop = async (userGoods: UserGoods) => {

  const XAccessToken = localStorage.getItem('XAccessToken');


  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.airdrop, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify(
        userGoods
      ),
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result) {
        // 放置结果数据 
        console.log("response-bandCard", result);
        return result;
      } else {
        return result;
      }
    } else if (response.status === 401) {

      return "请先登录"
    }
  }

}




/*  身份信息认证  */
export const idCardBind = async (userInfo: UserInfo) => {

  const XAccessToken = localStorage.getItem('XAccessToken');


  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.idCardBind, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify(
        userInfo
      ),
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result) {
        // 放置结果数据 
        console.log("response-idCardBind", result);
        return result;
      } else {
        return result;
      }
    } else if (response.status === 401) {

      return "请先登录"
    }
  }

}



/*  绑卡申请  */
export const bindBankCardApply = async (userCardInfo: UserCardInfo) => {

  const XAccessToken = localStorage.getItem('XAccessToken');


  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.bindBankCardApply, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify(
        userCardInfo
      ),
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result) {
        // 放置结果数据 
        console.log("response-bandCard", result);
        return result;
      } else {
        return result;
      }
    } else if (response.status === 401) {

      return "请先登录"
    }
  }

}




/*  绑卡确认 */
export const bindBankCardInfo = async (userCardInfo: UserCardInfo) => {

  const XAccessToken = localStorage.getItem('XAccessToken');


  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.bindBankCardInfo, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify(
        userCardInfo
      ),
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result) {
        // 放置结果数据 
        console.log("response-bandCard", result);
        return result;
      } else {
        return result;
      }
    } else if (response.status === 401) {

      return "请先登录"
    }
  }

}




/*  支付确认 */
export const fastPayConfirm = async (buyOrder: BuyOrder) => {

  const XAccessToken = localStorage.getItem('XAccessToken');


  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.fastPayConfirm, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify(
        buyOrder
      ),
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result) {
        // 放置结果数据 
        console.log("response-fastPayConfirm", result);
        return result;
      } else {
        return result;
      }
    } else if (response.status === 401) {

      return "请先登录"
    }
  }

}



/*  盲盒转赠 */
export const nftTransfer = async (wasaiTransfer: WasaiTransfer) => {

  const XAccessToken = localStorage.getItem('XAccessToken');


  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.nftTransfer, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify(
        wasaiTransfer
      ),
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result) {
        console.log("response-nftTransfer", result);
        return result;
      } else {
        return result;
      }
    } else if (response.status === 401) {

      return "请先登录"
    }
  }

}



/*  盲盒提取上链 */
export const nftTochain = async (wasaiTochain: WasaiTochain) => {

  const XAccessToken = localStorage.getItem('XAccessToken');


  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.toChainUser, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify(
        wasaiTochain
      ),
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result) {
        console.log("response-toChainUser", result);
        return result;
      } else {
        return result;
      }
    } else if (response.status === 401) {

      return "请先登录"
    }
  }

}


/*  支付确认 */
export const userDespointFastPayConfirm = async (deposit: Deposit) => {

  const XAccessToken = localStorage.getItem('XAccessToken');


  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.userDespointFastPayConfirm, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify(
        deposit
      ),
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result) {
        // 放置结果数据 
        console.log("response-userDespointFastPayConfirm", result);
        return result;
      } else {
        return result;
      }
    } else if (response.status === 401) {

      return "请先登录"
    }
  }

}




/* 查询转增记录  */
export const nftTransferHistory = async (params: any, pageNo: string, pageSize: string) => {
  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */
  if ((XAccessToken)) {

    let url = API_CONFIG.nftTransferHistory;
    if (params) {
      let paramsArray: any[] = [];
      //拼接参数

      Object.keys(params).forEach(key => paramsArray.push(key + '=' + (typeof params[key] == 'undefined' ? "" : params[key])))
      if (url.search(/\?/) === -1) {
        url += '?' + paramsArray.join('&')
      } else {
        url += '&' + paramsArray.join('&')
      }
    }

    url += '&pageNo=' + pageNo
    url += '&pageSize=' + pageSize
    const response = await fetch(url, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result?.success) {

        console.log("response-queryPersonBattleList", result);
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}



/* 查询提现记录--个人 */
export const querySonList = async (phone: any) => {
  const response = await fetch("https://ai.wsxq.space/api/demo/test1?phone="+phone, {
    method: 'get', headers: {
      'Content-Type': 'application/json',
    },
  })
  const result = await response.json();
  return result;
  

}


export const BankCodeMap = {
  "ABC": "农业银行",
  "AE": "AE",
  "AHRCUB": "安徽省农村信用社联合社",
  "BCZ": "沧州银行",
  "BEEB": "鄞州银行",
  "BGZB": "贵州银行",
  "BHB": "河北银行",
  "BJRCB": "北京农商行",
  "BNC": "江西银行",
  "BOB": "北京银行",
  "BOC": "中国银行",
  "BOCD": "成都银行",
  "BOCDB": "承德银行",
  "BOD": "东莞银行",
  "BOHN": "海南银行",
  "BOIMCB": "内蒙古银行",
  "BOJX": "嘉兴银行",
  "BOLB": "洛阳银行",
  "BOLFB": "廊坊银行",
  "BONX": "宁夏银行",
  "BOSH": "上海银行",
  "BOSXB": "绍兴银行",
  "BOSZS": "石嘴山银行",
  "BOTSB": "唐山银行",
  "BOZ": "张家口市商业银行",
  "BSB": "包商银行",
  "BYK": "营口银行",
  "CBHB": "渤海银行",
  "CCAB": "长安银行",
  "CCB": "建设银行",
  "CDRCB": "成都农商银行",
  "CEB": "光大银行",
  "CFT": "零钱",
  "CIB": "兴业银行",
  "CITIC": "中信银行",
  "CMB": "招商银行",
  "CMBC": "民生银行",
  "COMM": "交通银行",
  "CQB": "重庆银行",
  "CQRCB": "重庆农商银行",
  "CQTGB": "重庆三峡银行",
  "CRB": "华润银行",
  "CSCB": "长沙银行",
  "CSRCB": "常熟农商银行",
  "CZB": "浙商银行",
  "CZCB": "稠州银行",
  "DANDONGB": "丹东银行",
  "DLB": "大连银行",
  "DRCB": "东莞农商银行",
  "DYB": "东营银行",
  "DYCCB": "德阳银行",
  "FBB": "富邦华一银行",
  "FDB": "富滇银行",
  "FJHXB": "福建海峡银行",
  "FJNX": "福建农信银行",
  "FUXINB": "阜新银行",
  "GDB": "广发银行",
  "GDHX": "广东华兴银行",
  "GDNYB": "南粤银行",
  "GDRCU": "广东农信银行",
  "GLB": "桂林银行",
  "GRCB": "广州农商银行",
  "GSB": "甘肃银行",
  "GSNX": "甘肃农信",
  "GXNX": "广西农信",
  "GYCB": "贵阳银行",
  "GZCB": "广州银行",
  "GZNX": "贵州农信",
  "HAINNX": "海南农信",
  "HANAB": "韩亚银行",
  "HBCB": "湖北银行",
  "HBNX": "湖北农信",
  "HDCB": "邯郸银行",
  "HEBNX": "河北农信",
  "HFB": "恒丰银行",
  "HKB": "汉口银行",
  "HKBEA": "东亚银行",
  "HNNX": "河南农信",
  "HRBB": "哈尔滨银行",
  "HRXJB": "华融湘江银行",
  "HSB": "徽商银行",
  "HSBC": "恒生银行",
  "HUNNX": "湖南农信",
  "HUSRB": "湖商村镇银行",
  "HXB": "华夏银行",
  "HZB": "杭州银行",
  "ICBC": "工商银行",
  "JCB": "晋城银行",
  "JJCCB": "九江银行",
  "JLB": "吉林银行",
  "JLNX": "吉林农信",
  "JNRCB": "江南农商",
  "JRCB": "江阴农商行",
  "JSB": "江苏银行",
  "JSHB": "晋商银行",
  "JSNX": "江苏农商行",
  "JUFENGB": "临朐聚丰村镇银行",
  "JXNXB": "江西农信",
  "JZB": "晋中银行",
  "JZCB": "锦州银行",
  "KLB": "昆仑银行",
  "KRCB": "昆山农商",
  "KUERLECB": "库尔勒市商业银行",
  "LJB": "龙江银行",
  "LNNX": "辽宁农信",
  "LQT": "零钱通",
  "LSCCB": "乐山市商业银行",
  "LUZB": "柳州银行",
  "LWB": "莱商银行",
  "LYYHB": "辽阳银行",
  "LZB": "兰州银行",
  "MASTERCARD": "MASTERCARD",
  "MINTAIB": "民泰银行",
  "NBCB": "宁波银行",
  "NCB": "宁波通商银行",
  "NJCB": "南京银行",
  "NMGNX": "内蒙古农信",
  "NYCCB": "南阳村镇银行",
  "ORDOSB": "鄂尔多斯银行",
  "PAB": "平安银行",
  "PSBC": "邮政储蓄银行",
  "PZHCCB": "攀枝花银行",
  "QDCCB": "青岛银行",
  "QHNX": "青海农信",
  "QLB": "齐鲁银行",
  "QSB": "齐商银行",
  "RZB": "日照银行",
  "SCNX": "四川农信",
  "SDEB": "顺德农商行",
  "SDRCU": "山东农信",
  "SHINHAN": "新韩银行",
  "SJB": "盛京银行",
  "SPDB": "浦发银行",
  "SRB": "上饶银行",
  "SRCB": "上海农商银行",
  "SUZB": "苏州银行",
  "SXNX": "山西农信",
  "SXXH": "陕西信合",
  "SZRCB": "深圳农商银行",
  "TACCB": "泰安银行",
  "TCRCB": "太仓农商行",
  "TJB": "天津银行",
  "TJBHB": "天津滨海农商行",
  "TRCB": "天津农商",
  "TZB": "台州银行",
  "URB": "联合村镇银行",
  "VISA": "VISA",
  "WEB": "微众银行",
  "WFB": "潍坊银行",
  "WHB": "威海商业银行",
  "WHRC": "武汉农商行",
  "WJRCB": "吴江农商行",
  "WLMQB": "乌鲁木齐银行",
  "WRCB": "无锡农商",
  "WZB": "温州银行",
  "XAB": "西安银行",
  "XIB": "厦门国际银行",
  "XJRCCB": "新疆农信银行",
  "XMCCB": "厦门银行",
  "YNRCCB": "云南农信",
  "YRRCB": "黄河农商银行",
  "YTB": "烟台银行",
  "ZJB": "紫金农商银行",
  "ZJLXRB": "兰溪越商银行",
  "ZJRCUB": "浙江农信",
  "ZJTLCB": "浙江泰隆银行",
  "ZRCB": "张家港农商行",
  "ZYB": "中原银行",
  "ZZB": "郑州银行"
}
